import React, { useState, useRef, useEffect } from "react";
import Button from "../atoms/Button";
import Tooltip from "../atoms/Tooltip";
import Check from "../../assets/check.svg";
import clock from "../../assets/clock.svg";
import Dots from "../../assets/dots-horizontal.svg";
import calendarGray from "../../assets/calendar-gray.svg";
import Info from "../../assets/info-circle.svg";
import calendarGreen from "../../assets/calendarGreen.svg";
import close from "../../assets/x-close.svg";
import AlertOctagonIcon from "../../assets/alert-octagon.svg";
import SingleStar from "../../assets/rating-single-star.svg";
import StarGroup from "../../assets/light-star-group.svg";
import { whatsappClientNumber } from "../../services/utils/constants";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import ConfirmationModal from "./ConfirmationModal";
import FeedbackModal from "./FeedbackModal";
import RatingStars from "./RatingStars";
import { useNavigate } from "react-router-dom";
import {
  getTimeDifference,
  getTimeDifferenceTemp,
} from "../../services/globalFunctions";
import { getPatientTherapistChatList } from "../../redux/agora/agoraThunks";
import { useDispatch, useSelector } from "react-redux";

const SessionCard = ({
  data,
  handleCancel,
  handleRescheduleRequest,
  handleReschedule,
  loader,
  reScheduleLoader,
  selectedTherapist,
}) => {
  const dispatch = useDispatch();
  const ratingData = useSelector((state) => state.patient.sessionsData);
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [ratingValue, setRatingValue] = useState(false);
  const [finalRating, setFinalRating] = useState();
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };
  useEffect(() => {
    if (!ratingValue) {
      setRatingValue(ratingData?.rating_star);
    }
  }, [ratingValue]);

  useEffect(() => {
    setFinalRating(data?.session_rating?.rating_star);
  }, [data]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFeedbackOpenModal = () => {
    setIsFeedbackModalOpen(true);
  };
  const handleFeedbackCloseModal = () => {
    setIsFeedbackModalOpen(false);
  };
  const handleSubmit = () => {
    setIsFeedbackModalOpen(false);
  };

  const handleConfirm = (id) => {
    // Handle confirmation logic here
    handleCancel(id);
    handleCloseModal();
  };

  const handleChat = async () => {
    const res = await dispatch(
      getPatientTherapistChatList({
        keyword: "",
        sort: "newest",
      })
    );
    if (res) {
      const therapist_info = res?.payload?.data?.find(
        (i) => i.id == data?.therapist_info?.id
      );
      navigate(`/messages`, {
        state: { therapist_info: therapist_info },
      });
    }
  };

  const handleJoinSession = () => {
    if (getTimeDifference(data?.appointment_time) > 15)
      toast.error("You can not join early!");
    else
      window.open(
        `/dashboard/session/?patient_id=${data?.patient}&appointment=${data?.id}&therapist_id=${data?.therapist}`
      );
  };

  return (
    <div className="before:w-3 before:h-3 before:rounded-full before:bg-white before:absolute before:-left-[25px] lg:before:-left-[41px] before:top-1 relative bg-white rounded-2xl border border-gray-200 shadow-therapistCard">
      <div className="lg:flex justify-between">
        <div className="px-4 pt-4 pb-6 session-details">
          <div className="mb-6 text-sm flex justify-between lg:hidden">
            {data?.status == "cancelled" ? (
              <div className="font-medium">Cancelled</div>
            ) : (
              <div>
                <span className="font-medium">
                  Session {data?.sr_num < 10 ? "0" : ""}
                  {data?.sr_num}
                </span>{" "}
                . {data?.days}
              </div>
            )}

            {data.therapist_verification && (
              <div className="flex items-center space-x-1 text-xs text-gray-500">
                <img src={Check} className="w-4 h-4" />
                <span>Verified by therapist</span>
              </div>
            )}
          </div>
          <div className="text-sm text-gray-400 font-semibold uppercase">
            {data.session_date}
          </div>
          <div className="text-xl font-medium space-x-4 flex items-center mt-1">
            <span>{data.start_time}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                opacity="0.4"
                d="M4.16634 10H15.833"
                stroke="#667085"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.99967 15.8346L15.833 10.0013L9.99967 4.16797"
                stroke="#667085"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="text-gray-400">{data.end_time}</span>
          </div>
        </div>
        <div className="px-4 flex items-center join-session">
          {data.status === "cancelled" ? (
            <div className="text-danger-900 bg-danger-100 px-4 py-3 font-medium rounded-3xl w-full text-center">
              Session Cancelled
            </div>
          ) : data.status === "rescheduled" ? (
            <div className="text-warning-900 bg-warning-100 px-4 py-3 font-medium rounded-3xl w-full text-center">
              Session rescheduled
            </div>
          ) : data.status === "completed" ? (
            <div className="text-success-900 bg-success-100 px-4 py-3 font-medium rounded-3xl flex items-center justify-center space-x-2 w-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.2559 4.41009C17.5814 4.73553 17.5814 5.26317 17.2559 5.5886L8.08926 14.7553C7.76382 15.0807 7.23618 15.0807 6.91074 14.7553L2.74408 10.5886C2.41864 10.2632 2.41864 9.73553 2.74408 9.41009C3.06951 9.08466 3.59715 9.08466 3.92259 9.41009L7.5 12.9875L16.0774 4.41009C16.4028 4.08466 16.9305 4.08466 17.2559 4.41009Z"
                  fill="#53C645"
                  className="mr-1"
                />
              </svg>
              <span>Session Completed</span>
            </div>
          ) : data.status === "no-show" ? (
            <div className="text-gray-400 bg-gray-100 px-4 py-3 font-medium rounded-3xl flex items-center justify-center space-x-2 w-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M21.5351 17.2924C21.9591 17.2826 22.3621 17.0931 22.6406 16.767C22.9198 16.4401 22.9642 16.0468 22.9811 15.8559C23.0001 15.642 23 15.3762 23 15.1059V8.89407C23 8.62371 23.0001 8.35791 22.9811 8.14396C22.9642 7.95307 22.9198 7.55981 22.6406 7.23287C22.3288 6.86775 21.861 6.67399 21.3823 6.71166C20.9537 6.7454 20.6442 6.99211 20.4973 7.11512C20.3326 7.253 20.1447 7.44092 19.9536 7.63212L18 9.58573C17.9998 8.85799 17.9968 8.25011 17.9558 7.74818C17.9099 7.18608 17.8113 6.66938 17.564 6.18404C17.1805 5.43139 16.5686 4.81947 15.816 4.43598C15.3306 4.18868 14.8139 4.09012 14.2518 4.04419C13.7106 3.99998 13.0463 3.99999 12.2413 4H8.24264L21.5351 17.2924Z"
                  fill="#98A2B3"
                />
                <path
                  d="M2.70711 1.29289C2.31658 0.902369 1.68342 0.902369 1.29289 1.29289C0.90237 1.68342 0.90237 2.31658 1.29289 2.70711L3.07787 4.49209C2.37384 4.87784 1.80127 5.46709 1.43598 6.18403C1.18869 6.66937 1.09012 7.18607 1.0442 7.74817C0.999979 8.28935 0.999989 8.9537 1 9.75865V14.2413C0.999989 15.0462 0.999979 15.7106 1.0442 16.2518C1.09012 16.8139 1.18869 17.3306 1.43598 17.816C1.81947 18.5686 2.43139 19.1805 3.18404 19.564C3.66938 19.8113 4.18608 19.9099 4.74818 19.9558C5.28936 20 5.95371 20 6.75866 20H12.2413C13.0462 20 13.7106 20 14.2518 19.9558C14.8139 19.9099 15.3306 19.8113 15.816 19.564C16.317 19.3087 16.7557 18.9522 17.1061 18.5203L21.2929 22.7071C21.6834 23.0976 22.3166 23.0976 22.7071 22.7071C23.0976 22.3166 23.0976 21.6834 22.7071 21.2929L2.70711 1.29289Z"
                  fill="#98A2B3"
                />
              </svg>
              <span>No Show</span>
              <div className="group relative">
                <img src={Info} className="cursor-pointer" />
                <Tooltip
                  direction="left"
                  right="-right-5 after:right-[18px] before:right-4"
                >
                  This session was marked as &quot;No Show&quot; by the customer
                  support team.
                </Tooltip>
              </div>
            </div>
          ) : (
            <Button
              onClick={() =>
                getTimeDifference(data?.appointment_time) > 15
                  ? {}
                  : handleJoinSession()
              }
              className={`flex items-center justify-center space-x-2 w-full ${data.is_active &&
                getTimeDifference(data?.appointment_time) <= 15
                ? "text-gray-900 "
                : "text-gray-400 bg-gray-100"
                }`}
            >
              {data.is_active &&
                getTimeDifference(data?.appointment_time) <= 15 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.12"
                      d="M2 9.8C2 8.11984 2 7.27976 2.32698 6.63803C2.6146 6.07354 3.07354 5.6146 3.63803 5.32698C4.27976 5 5.11984 5 6.8 5H12.2C13.8802 5 14.7202 5 15.362 5.32698C15.9265 5.6146 16.3854 6.07354 16.673 6.63803C17 7.27976 17 8.11984 17 9.8V14.2C17 15.8802 17 16.7202 16.673 17.362C16.3854 17.9265 15.9265 18.3854 15.362 18.673C14.7202 19 13.8802 19 12.2 19H6.8C5.11984 19 4.27976 19 3.63803 18.673C3.07354 18.3854 2.6146 17.9265 2.32698 17.362C2 16.7202 2 15.8802 2 14.2V9.8Z"
                      fill="#009688"
                      stroke="#009688"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M22 8.93137C22 8.32555 22 8.02265 21.8802 7.88238C21.7763 7.76068 21.6203 7.69609 21.4608 7.70865C21.2769 7.72312 21.0627 7.93731 20.6343 8.36569L17 12L20.6343 15.6343C21.0627 16.0627 21.2769 16.2769 21.4608 16.2914C21.6203 16.3039 21.7763 16.2393 21.8802 16.1176C22 15.9774 22 15.6744 22 15.0686V8.93137Z"
                      stroke="#009688"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2 9.8C2 8.11984 2 7.27976 2.32698 6.63803C2.6146 6.07354 3.07354 5.6146 3.63803 5.32698C4.27976 5 5.11984 5 6.8 5H12.2C13.8802 5 14.7202 5 15.362 5.32698C15.9265 5.6146 16.3854 6.07354 16.673 6.63803C17 7.27976 17 8.11984 17 9.8V14.2C17 15.8802 17 16.7202 16.673 17.362C16.3854 17.9265 15.9265 18.3854 15.362 18.673C14.7202 19 13.8802 19 12.2 19H6.8C5.11984 19 4.27976 19 3.63803 18.673C3.07354 18.3854 2.6146 17.9265 2.32698 17.362C2 16.7202 2 15.8802 2 14.2V9.8Z"
                      stroke="#009688"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}

              <span>Join Session</span>
            </Button>
          )}
        </div>
        {data.parent_appointment > 0 && isMobile && (
          <div className="px-1.5 py-1.5 mx-4 my-4 flex items-center space-x-1 border border-gray-200 rounded-lg">
            <img src={calendarGreen} className="max-w-4" />
            <span className="text-xs font-medium text-gray-500">
              Session was rescheduled
            </span>
            <div className="relative z-10 group !ml-auto !mr-0">
              <img src={Info} className="cursor-pointer" />
              <Tooltip
                right="-right-4 after:right-4 before:right-3.5"
                direction={`${isMobile ? "left" : "auto"}`}
              >
                {data.rescheduled_info}
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      {data?.status == "reschedule_requested" ? (
        <div className="flex justify-between border-t border-t-gray-200 py-2.5 px-3 rounded-b-2xl mt-2.5 lg:mt-0 bg-orange-100">
          <div className="flex items-center space-x-2">
            <img src={AlertOctagonIcon} />
            <div className="text-sm font-medium text-orange-900">
              Therapist has requested to reschedule this session
            </div>
          </div>
          <div className={`${isMobile ? "w-2/3" : "flex"} items-center`}>
            <div
              className="text-xs text-gray-500 font-medium px-2 py-1 cursor-pointer"
              onClick={() =>
                window.open(`https://wa.me/${whatsappClientNumber}`)
              }
            >
              Contact support
            </div>
            {reScheduleLoader ? (
              <div className="text-xs font-medium text-white bg-teal-900 px-2 py-1 rounded-full cursor-pointer w-32 h-6 flex items-center justify-center">
                <div className="border-t-2 border-teal-100 border-solid rounded-full h-2 w-2 animate-spin"></div>
              </div>
            ) : (
              <div
                className="text-xs font-medium text-white bg-teal-900 px-2 py-1 rounded-full cursor-pointer"
                onClick={() => handleRescheduleRequest(data)}
              >
                Reschedule session
              </div>
            )}
          </div>
        </div>
      ) : data?.status === "completed" ? (
        finalRating ? (
          <div className="font-medium flex justify-between border-t border-t-gray-200 mt-2.5 lg:mt-0 session-actions">
            <div className="flex text-xs text-gray-500">
              <div className="px-3 py-3.5 flex items-center space-x-1">
                <img src={clock} />
                <span>{data.session_duration}</span>
              </div>
              <div className="px-3 py-2.5 flex items-center border-l border-l-gray-200 space-x-1">
                <RatingStars value={finalRating} />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-between px-3 py-2.5 border-t border-t-gray-200 rounded-b-2xl mt-2.5 lg:mt-0 bg-teal-50">
            <div className="flex items-center space-x-2 flex-1">
              <img src={SingleStar} />
              <div className="text-xs lg:text-sm font-medium text-teal-900">
                {ratingValue
                  ? "Thanks For providing Feedback"
                  : "How was your session, please provide us feedback"}
              </div>
            </div>
            {(
              ratingData
                ? data.id === ratingData.appointment && ratingValue
                : ratingValue
            ) ? (
              <RatingStars value={ratingValue} />
            ) : (
              <div
                className="flex items-center cursor-pointer"
                onClick={handleFeedbackOpenModal}
              >
                <img src={StarGroup} />
              </div>
            )}
          </div>
        )
      ) : (
        <div className="font-medium flex justify-between border-t border-t-gray-200 mt-2.5 lg:mt-0 session-actions">
          <div className="flex text-xs text-gray-500">
            <div className="px-3 py-3.5 flex items-center space-x-1">
              <img src={clock} />
              <span>{data.session_duration}</span>
            </div>
            {data.therapist_verification && !isMobile && (
              <div className="px-3 py-3.5 flex items-center border-l border-l-gray-200 space-x-1">
                <img src={Check} />
                <span>Verified by therapist</span>
              </div>
            )}
            {data.status === "cancelled" && (
              <div className="text-xs text-danger-900 px-3 py-3.5 border-l border-l-gray-200">
                Cancelled
              </div>
            )}
            {data.status === "rescheduled" && (
              <div className="text-xs text-warning-900 px-3 py-3.5 border-l border-l-gray-200">
                Rescheduled
              </div>
            )}
            {/* <span>Parent: {data.parent_appointment}</span> */}
            {data.parent_appointment > 0 && !isMobile && (
              <div className="px-3 py-3.5 flex items-center space-x-1 border-l border-l-gray-200">
                <img src={calendarGreen} className="max-w-4" />
                <span>Session was rescheduled</span>
                <div className="relative z-10 group">
                  <img src={Info} className="cursor-pointer" />
                  <Tooltip
                    right="-right-4 after:right-4 before:right-3.5"
                    direction={`${isMobile ? "left" : "auto"}`}
                  >
                    {data.rescheduled_info}
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
          {data.is_active ? (
            <div className="flex">
              <div
                onClick={handleChat}
                className="px-4 py-2.5 border-l border-l-gray-200 cursor-pointer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M20.9996 11.5C20.9996 16.1944 17.194 20 12.4996 20C11.4228 20 10.3928 19.7998 9.44478 19.4345C9.27145 19.3678 9.18478 19.3344 9.11586 19.3185C9.04807 19.3029 8.999 19.2963 8.92949 19.2937C8.85881 19.291 8.78127 19.299 8.62619 19.315L3.50517 19.8444C3.01692 19.8948 2.7728 19.9201 2.6288 19.8322C2.50337 19.7557 2.41794 19.6279 2.3952 19.4828C2.36909 19.3161 2.48575 19.1002 2.71906 18.6684L4.35472 15.6408C4.48942 15.3915 4.55677 15.2668 4.58728 15.1469C4.6174 15.0286 4.62469 14.9432 4.61505 14.8214C4.60529 14.6981 4.55119 14.5376 4.443 14.2166C4.15547 13.3636 3.99962 12.45 3.99962 11.5C3.99962 6.80558 7.8052 3 12.4996 3C17.194 3 20.9996 6.80558 20.9996 11.5Z"
                    stroke="#98A2B3"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {data.status === "scheduled" ? (
                <div>
                  <div
                    className={`px-4 py-2.5 border-l border-l-gray-200 cursor-pointer rounded-br-2xl ${open ? "bg-teal-100" : ""
                      }`}
                    onClick={() => setOpen(!open)}
                  >
                    <img src={Dots} />
                  </div>
                  <ul
                    className={`absolute py-4 max-w-xs w-full bg-white z-10 shadow-therapistInfoCard rounded-2xl right-0 font-normal space-y-4 ${open ? "block" : "hidden"
                      }`}
                    ref={dropdownRef}
                  >
                    <li
                      className={`${getTimeDifferenceTemp(data?.appointment_time) < 1440
                        ? "group relative"
                        : "hover:opacity-50"
                        }${data.reschedule_permission ? "" : "text-gray-400"
                        } flex py-2 px-4 justify-between cursor-pointer`}
                      onClick={() => {
                        data.reschedule_permission &&
                          getTimeDifferenceTemp(data?.appointment_time) > 1440
                          ? handleReschedule({
                            appointment: "reschedule",
                            appointment_id: data.id,
                            session_date: data.session_date,
                            start_time: data.start_time,
                          })
                          : toast("Reschedule limit reached");
                      }}
                    >
                      <div
                        className={`flex space-x-4 ${getTimeDifferenceTemp(data?.appointment_time) < 1440
                          ? "opacity-50"
                          : ""
                          }`}
                      >
                        {loader ? (
                          <div className="flex items-center justify-center">
                            <div className="border-t-2 border-teal-300 border-solid rounded-full h-6 w-6 animate-spin"></div>
                          </div>
                        ) : (
                          <img src={calendarGray} />
                        )}

                        <span>Reschedule Session</span>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        className={`${getTimeDifferenceTemp(data?.appointment_time) < 1440
                          ? "opacity-50"
                          : ""
                          }`}
                      >
                        <path
                          d="M9 18L15 12L9 6"
                          stroke={
                            data.reschedule_permission ? "#101828" : "#98A2B3"
                          }
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <Tooltip
                        direction="left"
                        right="-right-0 after:right-[18px] before:right-4"
                      >
                        This session can no longer be rescheduled as it is about
                        to start in less than 24 hours from now
                      </Tooltip>
                    </li>
                    <li
                      className={`${getTimeDifferenceTemp(data?.appointment_time) < 1440
                        ? "group relative"
                        : "hover:opacity-50"
                        }${data.cancel_permission ? "" : "text-gray-400"
                        } flex py-2 px-4 justify-between cursor-pointer`}
                      onClick={() => {
                        data.cancel_permission &&
                          getTimeDifferenceTemp(data?.appointment_time) > 1440
                          ? handleOpenModal()
                          : toast("Cancelation limit reached");
                        getTimeDifferenceTemp(data?.appointment_time) > 1440
                          ? setOpen(false)
                          : null;
                      }}
                    >
                      <div
                        className={`flex space-x-4 ${getTimeDifferenceTemp(data?.appointment_time) < 1440
                          ? "opacity-50"
                          : ""
                          }`}
                      >
                        <img src={close} />
                        <span>Cancel Session</span>
                      </div>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        className={`${getTimeDifferenceTemp(data?.appointment_time) < 1440
                          ? "opacity-50"
                          : ""
                          }`}
                      >
                        <path
                          d="M9 18L15 12L9 6"
                          stroke={
                            data.cancel_permission ? "#101828" : "#98A2B3"
                          }
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <Tooltip
                        direction="left"
                        right="-right-0 after:right-[18px] before:right-4"
                      >
                        This session can no longer be cancelled as it is about
                        to start in less than 24 hours from now
                      </Tooltip>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
      <ConfirmationModal
        title={`Headsup, you're cancelling your session!`}
        message1={`Oops! It seems you're attempting to cancel the session with your therapist. S/he will be notified about this cancellation and your credit will be added back.`}
        message2={`Remember you have limited tries to cancel the session. Are you sure you still want to cancel your session?`}
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        onConfirm={() => handleConfirm(data.id)}
      />
      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onRequestClose={handleFeedbackCloseModal}
        onSubmit={handleSubmit}
        data={data}
        setRatingValue={(value) => setRatingValue(value)}
        selectedTherapist={selectedTherapist}
      />
    </div>
  );
};
export default SessionCard;
